import FlagIcon from '@mui/icons-material/Flag';
import Box from '@mui/material/Box';
import { CompactShowLayout, RaBox } from 'ra-compact-ui';
import { DateField, FunctionField, Labeled, SelectField, TextField, useRecordContext, useTranslate } from 'react-admin';

import { ResourceKey } from 'constants/index';
import { useHasAccess } from 'modules/auth';
import type { Parcel } from 'modules/parcels/types';
import { PermissionAction } from 'modules/roles';
import { InlineEditableField, SeoHead } from 'modules/ui';

import { parcelStatusOrdered } from '../../config';
import { CustomerIssueState, paymentModes, paymentStates } from '../../constants';
import CancelParcelDeliveryButton from '../CancelParcelDeliveryButton';
import { ChangeToPrepaidButton } from '../ChangeToPrepaidButton';
import EditPaymentStateButton from '../EditPaymentStateButton';
import ParcelContacts from '../ParcelContacts';
import ParcelHistory from '../ParcelHistory';

import { CustomerIssueButton } from '../CustomerIssueButton';
import { ParcelLabels } from '../ParcelLabels';
import { ParcelDirection } from '../ParcelDirection';
import { ParcelIssue } from '../ParcelIssue';
import { ParcelNote } from '../ParcelNote';
import { ParcelSize } from '../ParcelSize';
import { ParcelExtendStorageTime } from './ParcelExtendStorageTime';

import { PutInLockerButton } from '../PutInLockerButton';
import { ParcelSection } from './ParcelSection';
import { ParcelSections } from './ParcelSections';
import { MarkParcelAsDropdown } from './MarkParcelAsDropdown';

import * as Styled from './ParcelDetailLayout.styles';

const paymentModeChoices = paymentModes.map(mode => ({
    id: mode,
    name: `parcel.payment.method.${mode}`,
}));

const paymentStatusChoices = paymentStates.map(state => ({
    id: state,
    name: `parcel.payment.state.${state}`,
}));

const ParcelDetailLayout = () => {
    const translate = useTranslate();

    const record = useRecordContext<Parcel>();
    const access = {
        cancellation: useHasAccess(ResourceKey.PARCELS, PermissionAction.CANCEL),
        extendStorageTime: useHasAccess(ResourceKey.PARCELS, PermissionAction.EXTEND_STORAGE_TIME),
        editReferenceNumber: useHasAccess(ResourceKey.PARCELS, PermissionAction.EDIT_REFERENCE_NUMBER),
        changeStatus: useHasAccess(ResourceKey.PARCELS, PermissionAction.CHANGE_STATUS),
        editParcelSize: useHasAccess(ResourceKey.PARCELS, PermissionAction.EDIT_PARCEL_SIZE),
        markAsDelivered: useHasAccess(ResourceKey.PARCELS, PermissionAction.MARK_PARCEL_AS_DELIVERED),
        markAsLost: useHasAccess(ResourceKey.PARCELS, PermissionAction.MARK_PARCEL_AS_LOST),
        markAsMissing: useHasAccess(ResourceKey.PARCELS, PermissionAction.MARK_PARCEL_AS_MISSING),
        markAsReturned: useHasAccess(ResourceKey.PARCELS, PermissionAction.MARK_PARCEL_AS_RETURNED),
        putInLocker: useHasAccess(ResourceKey.PARCELS, PermissionAction.PUT_IN_LOCKER),
    };

    if (!record) {
        return null;
    }

    const paymentMethodField = (
        <SelectField source="payment.mode" label={translate('parcels.payment.method')} choices={paymentModeChoices} />
    );

    return (
        <>
            <SeoHead title={translate('parcels.list.title')} />

            <CompactShowLayout resource={ResourceKey.PARCELS} layoutComponents={[RaBox, ParcelSections, ParcelSection]}>
                <Styled.Header>
                    <Labeled>
                        <Styled.ParcelNumber source="parcelNumber" label="parcels.list.number" />
                    </Labeled>

                    <Styled.Actions>
                        {record.customerIssueState ? <CustomerIssueButton /> : <></>}
                        {access.markAsDelivered ||
                        access.markAsReturned ||
                        access.markAsLost ||
                        access.markAsMissing ? (
                            <MarkParcelAsDropdown />
                        ) : (
                            <></>
                        )}
                        {access.cancellation ? <CancelParcelDeliveryButton /> : <></>}
                        {access.putInLocker ? <PutInLockerButton /> : <></>}
                    </Styled.Actions>
                </Styled.Header>

                <ParcelSections>
                    <ParcelSection key="partner" gridArea="partner" noHeader>
                        <RaBox>
                            <TextField source="partnerName" label={translate('parcels.list.partner')} />
                            <InlineEditableField
                                key="referenceNumber"
                                resource={ResourceKey.PARCELS}
                                isEditPermission={access.editReferenceNumber}
                                source="referenceNumber"
                                successMessage="parcels.editReferenceNumber.success"
                                label={translate('parcels.detail.reference')}
                            />
                            <DateField source="createdAt" label={translate('parcels.list.created')} />
                            <DateField source="updatedAt" label={translate('parcels.list.updated')} showTime />
                            {record.slaDate ? (
                                <DateField source="slaDate" label={translate('parcels.list.sla')} showTime />
                            ) : (
                                <></>
                            )}
                        </RaBox>
                    </ParcelSection>

                    <ParcelSection key="parcel" gridArea="parcel" action={<ParcelLabels />}>
                        <RaBox flex="0 0 100%" display="flex" gap={6}>
                            <FunctionField
                                label={translate('parcels.list.location')}
                                render={(record: any) =>
                                    record.locationType && translate(`parcels.location.${record.locationType}`)
                                }
                            />
                            <InlineEditableField
                                label={translate('parcels.list.status')}
                                resource={ResourceKey.PARCELS}
                                isEditPermission={access.changeStatus}
                                source="status"
                                type="select"
                                items={parcelStatusOrdered.map(status => ({
                                    value: status,
                                    label: translate(`parcel.state.${status}`),
                                }))}
                                successMessage="parcels.changeStatus.success"
                                formatDisplayValue={status => translate(`parcel.state.${status}`)}
                                editLabel="action.change"
                                cancellable={true}
                            />
                            <ParcelDirection label="parcel.direction" />

                            <ParcelSize label="parcel.compartmentSize" isEditPermission={access.editParcelSize} />
                        </RaBox>
                    </ParcelSection>

                    <ParcelSection key="payment" gridArea="payment">
                        {record.payment.mode === 'cod' ? (
                            <RaBox flex="0 0 100%" display="flex" gap={6}>
                                <RaBox display="flex" alignItems="flex-end" gap={1}>
                                    {paymentMethodField}
                                    <ChangeToPrepaidButton />
                                </RaBox>
                                <FunctionField<Parcel>
                                    source="payment.price.amount"
                                    options={{ style: 'currency', currency: 'USD' }}
                                    label={translate('parcels.payment.amount')}
                                    render={record =>
                                        record
                                            ? `${record.payment.price.amount} ${record.payment.price.currency}`
                                            : null
                                    }
                                />
                                <RaBox display="flex" alignItems="flex-end" gap={1}>
                                    <SelectField
                                        source="payment.state"
                                        label={translate('parcels.payment.status')}
                                        choices={paymentStatusChoices}
                                    />
                                    <EditPaymentStateButton type="transferred" />
                                    <EditPaymentStateButton type="refunded" />
                                </RaBox>
                            </RaBox>
                        ) : (
                            paymentMethodField
                        )}
                    </ParcelSection>
                </ParcelSections>

                {access.extendStorageTime && record.id && <ParcelExtendStorageTime parcelId={record.id} />}

                {record.customerIssueState === CustomerIssueState.ACTIVE && (
                    <ParcelIssue icon={FlagIcon}>{translate('parcel.detail.customerIssue.open')}</ParcelIssue>
                )}

                {record.status === 'missing' && record.missingNote && (
                    <ParcelIssue note={record.missingNote}>
                        {translate(`parcel.detail.issue`)} {translate(`parcel.detail.issue.${record.status}`)}
                    </ParcelIssue>
                )}

                <ParcelHistory />
                <Box marginTop={3}>
                    <ParcelNote />
                </Box>
                <ParcelContacts />
            </CompactShowLayout>
        </>
    );
};

export default ParcelDetailLayout;
